// @flow
import React from 'react';
import { Link } from '@riseart/common';
import { splitName } from '@riseart/fe-utils';
import { activity as ENUM_ACTIVITY } from 'Enum';
import { extractImageFileFromData } from 'shared_services/riseart/utils/ImageUtils';
import { getTimeDiffLabel } from 'shared_services/riseart/utils/Utils';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';

/**
 * isIdentityMe
 *
 * @param {string | number} id
 * @param {string} type
 * @param {?Object} me
 * @returns {boolean}
 */
function isIdentityMe(id: string | number, type: string, me: ?Object = null): boolean {
  if (!me) {
    return false;
  }

  if (
    (type === 'visitor' && me.visitor && id === me.visitor.id) ||
    (type === 'user' && me.user && id === me.user.id)
  ) {
    return true;
  }

  return false;
}

/**
 * getActivityTitle
 *
 * @param {Object} data
 * @param {Function} translate
 * @param {Object} me
 * @param {?boolean} ?isCreatorMe = false
 * @param {?boolean} ?isObjectOwnerMe = false
 * @returns {string}
 */
function getActivityTitle(
  data: Object,
  translate: Function,
  me: Object,
  isCreatorMe: ?boolean = false,
  isObjectOwnerMe: ?boolean = false,
): string {
  const { creatorUrl } = data;
  const creatorName = creatorUrl ? (
    <Link to={creatorUrl} title={data.creatorName}>
      {data.creatorName}
    </Link>
  ) : (
    data.creatorName
  );
  const object = data.objectUrl ? (
    <Link to={data.objectUrl} title={data.objectTitle}>
      {data.objectTitle}
    </Link>
  ) : (
    data.objectTitle
  );
  const objectOwner =
    (data.objectOwnerName &&
      (data.objectOwnerUrl ? (
        <Link to={data.objectOwnerUrl} title={data.objectOwnerName}>
          {data.objectOwnerName}
        </Link>
      ) : (
        data.objectOwnerName
      ))) ||
    '';

  return translate(
    { id: `components.activity.renderers.${data.type}${isCreatorMe ? '-creator-me' : ''}` },
    {
      creatorName,
      objectByOwner: isObjectOwnerMe
        ? object
        : translate({ id: 'common.objectByOwner' }, { object, owner: objectOwner }),
      object,
      objectOwner,
    },
  );
}

/**
 * getUserFirstName
 *
 * @param {string} name
 * @param {string} role
 * @returns {?string}
 */
function getUserFirstName(name: string, role: string): ?string {
  if (!name) {
    return;
  }

  return (role === 'user' && splitName(name).firstName) || name;
}

/**
 * ACTIVITY_MAPPERS
 */
const ACTIVITY_MAPPERS = {
  product: (activity, translate, activityImageType, me = null, source, locale) => {
    const isCreatorMe = isIdentityMe(activity.creatorId, activity.creatorType, me);
    const isObjectOwnerMe = isIdentityMe(activity.ownerId, activity.ownerType, me);
    const data = {
      type: activity.type,
      artDirectionKey: 'art.main',
      creatorName: activity.creatorName,
      creatorImage: activity.creatorImages,
      creatorUrl: UrlAssembler.activityCreator(activity, { locale }),
      objectTitle: activity.objectArtTitle,
      objectUrl: UrlAssembler.activityArt(activity, { locale }),
      objectImage: activity.objectArtImages || activity.objectImages,
      objectOwnerName: activity.objectArtArtistName,
      objectOwnerImage: activity.objectArtArtistImages,
      objectOwnerUrl: UrlAssembler.activityArtist(activity, { locale }),
      objectArtId: activity.objectArtId,
      objectArtTitle: activity.objectArtTitle,
      objectArtArtistFullName: activity.objectArtArtistName,
      createdAt: getTimeDiffLabel(new Date(), activity.activityDate, translate),
      title: '',
      altTitle: '',
    };

    data.title = getActivityTitle(data, translate, me, isCreatorMe, isObjectOwnerMe);
    data.creatorName = data.objectOwnerName;
    data.creatorImage = data.objectOwnerImage && data.objectOwnerImage[0];
    data.creatorUrl = data.objectOwnerUrl;
    data.altTitle =
      data.objectTitle && data.objectOwnerName
        ? translate(
            { id: 'common.objectByOwner' },
            { object: data.objectTitle, owner: data.objectOwnerName },
          )
        : data.objectTitle;
    data.objectImage =
      data.objectImage &&
      (extractImageFileFromData.activityImage(data.objectImage, activityImageType) ||
        extractImageFileFromData.activityImage(
          data.objectImage,
          ENUM_ACTIVITY.image.type.TYPE_SQUARE,
        ));

    return data;
  },
  profile: (activity, translate, activityImageType, me = null, source, locale) => {
    const isCreatorMe = isIdentityMe(activity.creatorId, activity.creatorType, me);
    const isObjectMe = isIdentityMe(activity.objectId, activity.objectType, me);
    const isObjectOwnerMe = isIdentityMe(activity.ownerId, activity.ownerType, me);
    const data = {
      type: activity.type,
      artDirectionKey: 'artist.banner',
      creatorName: getUserFirstName(activity.creatorName, activity.creatorRole),
      creatorImage: activity.creatorImages && activity.creatorImages[0],
      creatorUrl: !isCreatorMe ? UrlAssembler.activityCreator(activity, { locale }) : null,
      objectTitle: isObjectMe ? translate({ id: 'common.you' }) : activity.objectName,
      objectUrl: !isObjectMe ? UrlAssembler.activityObject(activity, { locale }) : null,
      objectArtId: activity.objectArtId,
      objectArtTitle: activity.objectArtTitle,
      objectArtArtistFullName: activity.objectArtArtistName,
      createdAt: getTimeDiffLabel(new Date(), activity.activityDate, translate),
      title: '',
      altTitle: '',
    };

    if (source === ENUM_ACTIVITY.feed.FEED_TRENDING) {
      // $FlowFixMe
      data.objectImage =
        activity.objectArtImages &&
        (extractImageFileFromData.activityImage(activity.objectArtImages, activityImageType) ||
          extractImageFileFromData.activityImage(
            activity.objectArtImages,
            ENUM_ACTIVITY.image.type.TYPE_SQUARE,
          ));
    }

    data.title = getActivityTitle(data, translate, me, isCreatorMe, isObjectOwnerMe);
    data.altTitle = data.objectTitle;

    return data;
  },
  common:
    (artDirectionKey) =>
    (activity, translate, activityImageType, me = null, source, locale) => {
      const isCreatorMe = isIdentityMe(activity.creatorId, activity.creatorType, me);
      const isObjectOwnerMe = isIdentityMe(activity.ownerId, activity.ownerType, me);

      const data = {
        type: activity.type,
        artDirectionKey,
        creatorName: getUserFirstName(activity.creatorName, activity.creatorRole),
        creatorImage: activity.creatorImages && activity.creatorImages[0],
        creatorUrl: !isCreatorMe ? UrlAssembler.activityCreator(activity, { locale }) : null,
        objectTitle: activity.objectName,
        objectUrl: UrlAssembler.activityObject(activity, { locale }),
        objectImage: activity.objectImages,
        objectArtId: activity.objectArtId,
        objectArtTitle: activity.objectArtTitle,
        objectArtArtistFullName: activity.objectArtArtistName,
        createdAt: getTimeDiffLabel(new Date(), activity.activityDate, translate),
        title: '',
        altTitle: '',
      };

      data.title = getActivityTitle(data, translate, me, isCreatorMe, isObjectOwnerMe);
      data.altTitle = data.objectTitle;
      data.objectImage =
        data.objectImage &&
        (extractImageFileFromData.activityImage(data.objectImage, activityImageType) ||
          extractImageFileFromData.activityImage(
            data.objectImage,
            ENUM_ACTIVITY.image.type.TYPE_SQUARE,
          ));

      return data;
    },
};

export const ActivityRendererMapper: Object = {
  [ENUM_ACTIVITY.type.TYPE_CART_ADD]: ACTIVITY_MAPPERS.product,
  [ENUM_ACTIVITY.type.TYPE_PRODUCT_SOLD]: ACTIVITY_MAPPERS.product,
  [ENUM_ACTIVITY.type.TYPE_PRODUCT_RENTAL]: ACTIVITY_MAPPERS.product,
  [ENUM_ACTIVITY.type.TYPE_PRODUCT_AVAILABLE]: ACTIVITY_MAPPERS.product,
  [ENUM_ACTIVITY.type.TYPE_FAVORITE_PRODUCT]: ACTIVITY_MAPPERS.product,
  [ENUM_ACTIVITY.type.TYPE_ARTIST_SHORTLISTED]: ACTIVITY_MAPPERS.profile,
  [ENUM_ACTIVITY.type.TYPE_FOLLOW_SHORTLISTED]: ACTIVITY_MAPPERS.profile,
  [ENUM_ACTIVITY.type.TYPE_EVENT_CREATE]: ACTIVITY_MAPPERS.common('event.main'),
  [ENUM_ACTIVITY.type.TYPE_EVENT_UPDATE]: ACTIVITY_MAPPERS.common('event.main'),
  [ENUM_ACTIVITY.type.TYPE_EVENT_CANCEL]: ACTIVITY_MAPPERS.common('event.main'),
  [ENUM_ACTIVITY.type.TYPE_EVENT_RSVP_YES]: ACTIVITY_MAPPERS.common('event.main'),
  [ENUM_ACTIVITY.type.TYPE_ARTICLE_CREATE]: ACTIVITY_MAPPERS.common('article.main'),
  [ENUM_ACTIVITY.type.TYPE_COLLECTION_CREATE]: ACTIVITY_MAPPERS.common('collection.main'),
};
